.LoginSignIn {
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    padding-top: 5em;
}

.ou {
    width: 10%;
    margin: 10em 0;
}

.Accueil-Welcome {
    font-size: 1.5em;
    text-align: center;
    margin-top: 2em;
}

.accueilSlogan {
    font-size: 4em;
    margin: 0 auto;
    text-align: center;
    padding-top: 9%;
    animation: fadein 6s;
    -moz-animation: fadein 6s; /* Firefox */
    -webkit-animation: fadein 6s; /* Safari et Chrome */
    -o-animation: fadein 6s; /* Opera */
}

#Logo_AGoodService {
    height: 3em;
    padding-right: 1em;
}

@media (max-width: 65em) {
    .accueilSlogan {
        font-size: 3em;
        margin-top: 20%;
    }
}

@media (max-width: 45.5em) {
    .LoginSignIn {
        display: block;
        margin: 0 1em;
        padding-top: 1em;
    }

    .ou {
        margin: 2em auto;
    }

    .accueilSlogan {
        font-size: 2em;
        margin-top: 40%;
    }
}

@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { /* sur Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein { /* sur Safari et Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein { /* sur Opera */
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}